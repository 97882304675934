import {
  AnyObject,
  AuthProvider,
  Headers,
  IConnector,
  Injectable,
  IResponse,
  ResourceActionFailed
} from '@movecloser/front-core'

import { ISelenaFormService, FormOptions } from './selenaForm.contracts'
import { SelenaFormModuleVersion } from '../../../../modules/SelenaForm/SelenaForm.config'
import { resolveFromStatus } from '../../../../support'
import { ISiteService } from '../../../../contexts'

/**
 * Field names required by selena-forms API.
 */
const Field = {
  SmEmail: 'sm-email',
  Email: 'email',
  SmPhone: 'sm-phone',
  Phone: 'phone',
  SmName: 'sm-name',
  Fullname: 'fullName',
  Date: 'date',
  Webinar: 'webinar',

  TrainingAddress: 'trainingAddress',
  TrainingZipCode: 'postalCode',
  TrainingCity: 'trainingCity',
  TrainingDate: 'trainingDate',

  AcceptAll: 'accept-all',
  Accept1: 'accept-this-1',
  Accept2: 'accept-this-2',
  Accept3: 'accept-this-3',
  Accept4: 'accept-this-4',
  Accept5: 'accept-this-5',

  Fields: 'fields',
  Name: 'name',
  Project: 'project',
  ProjectAddress: 'projectAddress',
  City: 'city',
  ZipCode: 'zipCode',
  ProjectType: 'projectType',
  Systems: 'systems',
  NumberOfSealedMb: 'numberOfSealedMb',
  WindowNumber: 'windowNumber',
  closingDate: 'closingDate',
  IssueDate: 'issueDate',
  Type: 'type',
  InstallationType: 'installationType',
  BuyingPlace: 'buyingPlace',
  AttachedFiles: 'attachedFiles',
  AuthorFullName: 'authorFullName',

  PersonalDataAgreement: 'personalDataAgreement',
  CommercialDataAgreement: 'commercialAgreement',

  VatTaxId: 'vatTaxId',

  AdminEmail: 'adminEmail',
  AdminFirstName: 'adminFirstName',
  AdminLastName: 'adminLastName',
  CompanyEmail: 'companyEmail',
  CompanyStreet: 'companyStreet',
  CompanyCity: 'companyCity',
  CompanyRegion: 'companyRegion',
  CompanyPostCode: 'companyPostCode',
  CompanyCountryCode: 'companyCountryCode',
  CompanyTelephone: 'companyTelephone',
  CompanyLegalName: 'companyLegalName',
  CompanyName: 'companyName',
  CompanyVatTaxId: 'companyVatTaxId'
}

// Testing Only !
// Auth is required for IssueCertificate form and sending files.
let TestToken = ''
const TestAuthorizationHeaders = () => {
  return { Authorization: `Bearer ${TestToken}` }
}

async function authorize () {
  const data = {
    username: process.env.VUE_APP_MAGENTO_AUTH_USERNAME,
    password: process.env.VUE_APP_MAGENTO_AUTH_PASSWORD,
  }
  const response = await fetch(String(process.env.VUE_APP_MAGENTO_AUTH_URL), {
    method: 'POST',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  TestToken = await response.json()
}

// End Testing Only !

/**
 *
 */
@Injectable()
export class SelenaFormService implements ISelenaFormService {
  private readonly authProvider: AuthProvider
  private readonly connector: IConnector
  private readonly debug: boolean
  private readonly locale: string

  constructor (connector: IConnector, siteService: ISiteService, authProvider: AuthProvider, debug: boolean = false) {
    this.connector = connector
    this.debug = debug
    this.authProvider = authProvider
    this.locale = siteService.getActiveSiteLocale()

    // Debug is dev/storybook only!
    if (debug) {
      authorize()
    }
  }

  /**
   * Main sending method for handling forms with selena-form API.
   * @param formName
   * @param payload
   */
  public async send (formName: string, payload: AnyObject) {
    const mappingHandler = this.getFormMappingHandler(formName)

    if (!mappingHandler) {
      throw new Error(`Cannot find handler for given form: [${formName}]`)
    }

    const { name, resource, data, method } = mappingHandler(payload)

    switch (formName) {
      case SelenaFormModuleVersion.IssueCertificate:
        return await this.sendCertificate(name, resource, data, method)
      default:
        return await this.sendForm(name, resource, data, method)
    }
  }

  /**
   * Options object with options for fields in given form type.
   * @param formName
   */
  public async getOptions (formName: string): Promise<FormOptions> {
    let options: FormOptions = {}
    const consentsOptions = this.getConsentsOptions()

    switch (formName) {
      case SelenaFormModuleVersion.Webinar:
        options = await this.getWebinarOptions()
        break
      case SelenaFormModuleVersion.IssueCertificate:
        options = await this.getIssueCertificateOptions()
        break
    }

    return { ...options, ...consentsOptions }
  }

  /**
   * Fetch certificates from selena-forms API.
   */
  public async getCertificates () {
    const headers = this.debug ? TestAuthorizationHeaders() as Headers : this.authProvider.getAuthorizationHeader() as unknown as Headers
    const response: IResponse = await this.connector.call(
      'certificates',
      'list',
      {},
      {},
      headers
    )

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }

    return response.data as AnyObject[]
  }

  /**
   * Fetch webinars from selena-forms API.
   */
  public async getWebinars () {
    const response: IResponse = await this.connector.call(
      'forms',
      'webinars',
      {},
      { country_code: this.locale },
    )

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }

    return response.data.data
  }

  /**
   * Returns general consent options for forms.
   * @private
   */
  private getConsentsOptions (): FormOptions {
    return {
      consentsOptions: [
        {
          option: 'consent1',
          required: true
        },
        {
          option: 'consent2',
          required: true
        },
        {
          option: 'consent3',
          required: true
        },
        {
          option: 'consent4',
          required: true
        },
        {
          option: 'consent5',
          required: true
        }
      ]
    }
  }

  /**
   * Call for webinar options from API.
   * @private
   */
  private async getWebinarOptions (): Promise<FormOptions> {
    const webinars = await this.getWebinars()

    return {
      webinar: webinars.map((item: AnyObject) => {
        const date = new Date(item.starts_at)
        const dateString = `${date.toLocaleDateString('pl-PL', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })}, ${date.toLocaleTimeString().substring(0, 5)}`
        return {
          label: `${item.name} (${dateString})`,
          value: item.id || ''
        }
      })
    }
  }

  /**
   * Call for IssueCertificate options from API.
   * @private
   */
  private async getIssueCertificateOptions (): Promise<FormOptions> {
    return await new Promise(resolve => {
      resolve(
        {
          type: [
            {
              label: 'modules.SelenaForm.form.type.options.1',
              value: 'dom jednorodzinny'
            },
            {
              label: 'modules.SelenaForm.form.type.options.2',
              value: 'budynek wielorodzinny'
            },
            {
              label: 'modules.SelenaForm.form.type.options.3',
              value: 'budynek użyteczności publicznej'
            },
            {
              label: 'modules.SelenaForm.form.type.options.4',
              value: 'obiekt przemysłowy'
            },
          ],
          installationType: [
            {
              label: 'modules.SelenaForm.form.installationType.options.1',
              value: 'Okna'
            },
            {
              label: 'modules.SelenaForm.form.installationType.options.2',
              value: 'Drzwi'
            },
            {
              label: 'modules.SelenaForm.form.installationType.options.3',
              value: 'Okna i drzwi'
            }
          ]
        }
      )
    })
  }

  /**
   * Match form (version) with name accepted by selena-forms.
   * @param form
   * @private
   */
  private getFormMappingHandler (form: string) {
    switch (form) {
      case SelenaFormModuleVersion.NewTrainingDates:
        return this.handleNewTrainingDatesForm

      case SelenaFormModuleVersion.InviteToSite:
        return this.handleInviteToSiteForm

      case SelenaFormModuleVersion.Webinar:
        return this.handleWebinarForm

      case SelenaFormModuleVersion.IssueCertificate:
        return this.handleIssueCertificateForm

      case SelenaFormModuleVersion.ConnectUser:
        return this.handleConnectUserForm

      case SelenaFormModuleVersion.ConvertUserToB2B:
        return this.handleConvertUserToB2BForm

      default:
        return null
    }
  }

  /**
   * Send certificate to selena-forms.
   * @param name
   * @param resource
   * @param data
   * @param method
   * @private
   */
  private async sendCertificate (name: string, resource: string, data: AnyObject, method: string) {
    const headers = this.debug ? TestAuthorizationHeaders() as Headers : this.authProvider.getAuthorizationHeader() as unknown as Headers
    const response: IResponse = await this.connector.call(
      resource,
      method,
      { name },
      {
        ...data,
        source: this.getSource()
      },
      headers
    )

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }

    const files = data[Field.Fields][Field.AttachedFiles]

    if (files.length) {
      const certificateId = response.data.data.id
      files.forEach((file: File) => {
        this.sendCertificationFile(certificateId, file)
      })
    }
  }

  /**
   * Send SINGLE file to selena-forms.
   * @param id
   * @param file
   * @private
   */
  private async sendCertificationFile (id: string, file: File) {
    const headers = {
      'content-type': 'multipart/form-data',
      ...(this.debug ? TestAuthorizationHeaders() : this.authProvider.getAuthorizationHeader())
    }

    const formData = new FormData()
    formData.append(
      'file',
      new File([file], file.name, { type: file.type })
    )

    const response: IResponse = await this.connector.call(
      'certificates',
      'upload',
      { id },
      formData,
      headers
    )

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  /**
   * Send form to selena-forms
   * @param name
   * @param resource
   * @param data
   * @param method
   * @private
   */
  private async sendForm (name: string, resource: string, data: AnyObject, method: string) {
    const response: IResponse = await this.connector.call(
      resource,
      method,
      { name },
      {
        values: this.convertValuesToStrings(data),
        source: this.getSource()
      })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  /**
   * Get source of form.
   * @private
   */
  private getSource () {
    return {
      label: process.env.VUE_APP_NAME,
      url: window.location.toString()
    }
  }

  /**
   * Map consents to names required by selena-forms API.
   * @param payload
   */
  private static mapConsents = (payload: AnyObject) => {
    const { consent1, consent2, consent3, consent4, consent5 } = payload
    return {
      [Field.AcceptAll]: consent1 && consent2 && consent3 && consent4 && consent5,
      [Field.Accept1]: consent1,
      [Field.Accept2]: consent2,
      [Field.Accept3]: consent3,
      [Field.Accept4]: consent4,
      [Field.Accept5]: consent5
    }
  }

  /**
   * Handler for `NewTrainingDatesForm`
   * @param payload
   * @private
   */
  private handleNewTrainingDatesForm (payload: AnyObject) {
    const name = 'nowe-terminy-szkolen'
    const resource = 'forms'
    const method = 'postTo'
    const data = {
      [Field.SmEmail]: payload.email,
      [Field.SmPhone]: payload.phoneNumber,
      ...SelenaFormService.mapConsents(payload)
    }

    return { name, resource, data, method }
  }

  /**
   * Handler for `InviteToSiteForm`
   * @param payload
   * @private
   */
  private handleInviteToSiteForm (payload: AnyObject) {
    const name = 'trainingRequestForm'
    const resource = 'forms'
    const method = 'postTo'
    const data = {
      [Field.Fullname]: payload.name,
      [Field.Email]: payload.email,
      [Field.Phone]: payload.phoneNumber,
      [Field.TrainingDate]: payload.trainingDate,
      [Field.TrainingAddress]: payload.address,
      [Field.TrainingZipCode]: payload.zipCode,
      [Field.TrainingCity]: payload.city,

      // ! Here all consents will be selected, so I assume those are accepted as well.
      [Field.CommercialDataAgreement]: true,
      [Field.PersonalDataAgreement]: true
    }

    return { name, resource, data, method }
  }

  /**
   * Handler for `WebinarForm`
   * @param payload
   * @private
   */
  private handleWebinarForm (payload: AnyObject) {
    const name = 'lp-modal-webinar'
    const resource = 'forms'
    const method = 'postTo'
    const data = {
      [Field.SmName]: payload.name,
      [Field.SmEmail]: payload.email,
      [Field.SmPhone]: payload.phoneNumber,
      [Field.Webinar]: payload.webinar,
      ...SelenaFormService.mapConsents(payload)
    }

    return { name, resource, data, method }
  }

  /**
   * Handler for `IssueCertificateForm`
   * @param payload
   * @private
   */
  private handleIssueCertificateForm (payload: AnyObject) {
    const name = ''
    const resource = 'certificates'
    const method = 'create'
    const data = {
      [Field.Name]: payload.name,
      // [Field.AuthorFullName]: payload.author,
      [Field.AuthorFullName]: payload.company,
      [Field.Fields]: {
        [Field.CompanyName]: payload.company,
        [Field.Project]: payload.title,
        [Field.ProjectAddress]: payload.address,
        [Field.City]: payload.city,
        [Field.ZipCode]: payload.zipCode,
        [Field.ProjectType]: payload.type,
        [Field.Systems]: payload.systems,
        [Field.NumberOfSealedMb]: payload.sealAmount,
        [Field.WindowNumber]: payload.windowCount,
        [Field.closingDate]: payload.endDate,
        [Field.Type]: payload.Type,
        [Field.InstallationType]: payload.installationType,
        [Field.BuyingPlace]: payload.BuyingPlace,
        // [Field.IssueDate]: payload.certificationDate,
        [Field.AttachedFiles]: payload.files,
        [Field.AuthorFullName]: payload.name,
        ...SelenaFormService.mapConsents(payload)
      }
    }

    return { name, resource, data, method }
  }

  /**
   * Handles for 'ConnectUserForm'
   */
  private handleConnectUserForm (payload: AnyObject) {
    const name = 'b2b-connect-user'
    const resource = 'forms'
    const method = 'postTo'
    const data = {
      [Field.Email]: payload.email,
      [Field.VatTaxId]: payload.vatTaxId
    }

    return { name, resource, data, method }
  }

  /**
   * Handles for 'ConvertUserToB2B'
   */
  private handleConvertUserToB2BForm (payload: AnyObject) {
    const name = 'b2b-user-conversion'
    const resource = 'forms'
    const method = 'postTo'
    const data = {
      [Field.AdminEmail]: payload.adminEmail,
      [Field.AdminFirstName]: payload.adminFirstName,
      [Field.AdminLastName]: payload.adminLastName,
      [Field.CompanyEmail]: payload.companyEmail,
      [Field.CompanyStreet]: payload.companyStreet,
      [Field.CompanyCity]: payload.companyCity,
      [Field.CompanyRegion]: payload.companyRegion,
      [Field.CompanyPostCode]: payload.companyPostCode,
      [Field.CompanyCountryCode]: payload.companyCountryCode,
      [Field.CompanyTelephone]: payload.companyTelephone,
      [Field.CompanyLegalName]: payload.companyLegalName,
      [Field.CompanyName]: payload.companyName,
      [Field.CompanyVatTaxId]: payload.companyVatTaxId
    }

    return { name, resource, data, method }
  }

  /**
   * Converts object values to strings.
   * Selena-forms API requires payload to contain only strings.
   * @param obj
   * @private
   */
  private convertValuesToStrings (obj: AnyObject) {
    const entries = Object.entries(obj)
    const newEntries = entries.map(entry => {
      return entry.map(value => String(value))
    })
    return Object.fromEntries(newEntries)
  }
}
