// Copyright © 2021 Move Closer

import { AnyObject, IModel, MagicModel, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '../related'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AvailableSiteFaviconType {
  Png = 'png',
  Svg = 'svg'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum AvailableSiteLogoVariant {
  Dark = 'dark',
  Light = 'light'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ISite extends IModel<SiteData> {
  getProperty (key: string, defaultValue?: SitePropertyTypes): SitePropertyTypes

  isMaintenanceMode (): boolean
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SiteData {
  address: string
  domain: string
  basePath: string
  color: string
  favicon: Record<string, string>
  id: Identifier
  locale: string
  logo: Record<string, string>
  name: string
  currency: string
  postfix: string
  properties: { [key: string]: SitePropertyTypes }

  /**
   * Various redirection settings.
   */
  redirections: {
    errorPages: { [errorCode: number]: string }
    searchResults: string
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type SiteModel = MagicModel<SiteData, ISite>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type SitePropertyTypes = string | number | boolean | null | string[] | number[] | AnyObject | AnyObject[]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type SitesMap = Record<Identifier, ModelPayload>
