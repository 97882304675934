// Copyright © 2022 Move Closer

const _path = require('path')
const path = _path.posix ? _path.posix : _path
const __dirname = '/'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export default {
  plugins: [
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'async-data.ts'),
    [
      '@uvue/core/plugins/vuex',
      { onHttpRequest: true, fetch: true }
    ],
    '@uvue/core/plugins/prefetch',
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'user-agent', 'user-agent.plugin.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'error-clear.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'validate-setup.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'progress-bar.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'dictionaries.ts')
  ],
  css: {
    normal: 'extract',
    vue: 'extract'
  }
}
