import { Component, Mixins } from 'vue-property-decorator'
import throttle from 'lodash/throttle'
// import { DebouncedFunc } from 'lodash'

import { ListenToAuthMixin } from '../../auth/shared/mixins/listenToAuthMixin'
import { InitCartMixin } from '../../checkout/shared'

import { InitPrivacyMixin } from './privacy.mixin'
import { OrderStatusesMixin } from './orderStatuses.mixin'
import { InitTrustedShopMixin } from './initTrustedShop.mixin'
import InitWishListMixin from '../../wishlist/shared/mixins'

interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T> | undefined
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CoreMixin>({
  mounted (): void {
    this.setCSSVariables()
  },
  beforeDestroy (): void {
    this.unsetCSSVariables()
  }
})
export class CoreMixin extends Mixins<ListenToAuthMixin & InitCartMixin & InitPrivacyMixin & OrderStatusesMixin & InitWishListMixin & InitTrustedShopMixin>(
  ListenToAuthMixin,
  InitCartMixin,
  InitPrivacyMixin,
  OrderStatusesMixin,
  InitWishListMixin,
  InitTrustedShopMixin
) {
  // Here you register global properties for css.
  protected setCSSVariables (): void {
    CoreMixin.setViewportHeight()

    window.addEventListener('resize', this.updateCSSVariables)
  }

  protected unsetCSSVariables (): void {
    const root: HTMLElement = document.documentElement
    root.style.removeProperty('--vh')

    window.removeEventListener('resize', this.updateCSSVariables)
  }

  protected updateCSSVariables: DebouncedFunc<() => void> =
    throttle(this.setCSSVariables, 100)

  private static setViewportHeight (): void {
    const root: HTMLElement = document.documentElement
    root.style.setProperty('--vh', (root.clientHeight * 0.01) + 'px')
  }
}
