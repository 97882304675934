
import { ConnectorFactory } from '@movecloser/front-core/src/contracts/connector'
import { Injectable, MappingConfig, Repository, ResourceActionFailed } from '@movecloser/front-core'

import {
  CompanyCreateInput,
  CompanyData,
  ShortCreateCompanyInput, UpdateCompanyInput
} from '../../../../contexts/profile/contracts/company'
import { IGraphQL } from '../../../../contexts'
import { IAuthControl } from '../../../auth/contracts'
import { TokenModel } from '../../../auth/contracts/models'
import { resolveFromStatus } from '../../../../support'

import { Company } from '../../models/company'
import { companyAdapterMap } from '../../models/company.adapter'
import {
  CompanyCreateIntention,
  CompanyUpdateIntention,
  ShortCompanyCreateIntention
} from '../../intentions/company.intention'
import { CompanyModel } from '../../contracts/models'

import { ICompanyRepository } from './company.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class CompanyRepository extends Repository<CompanyData, Company> implements ICompanyRepository {
  protected map: MappingConfig = companyAdapterMap
  protected useAdapter: boolean = true

  private graphQl: IGraphQL
  private authControl: IAuthControl

  constructor (connectorFactory: ConnectorFactory, connector: IGraphQL, authControl: IAuthControl) {
    super(connectorFactory)
    this.graphQl = connector
    this.authControl = authControl
  }

  /**
   * @inheritDoc
   */
  public async getCompanyData (): Promise<CompanyModel> {
    const response = await this.graphQl.call('company', {})

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return this.composeModel(response.data.company, Company)
  }

  /**
   * @inheritDoc
   */
  public async createCompany (companyInput: CompanyCreateInput): Promise<CompanyModel> {
    const newCompany = new CompanyCreateIntention(companyInput)
    const response = await this.graphQl.call('createCompany', { input: newCompany.toRequest() })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return this.composeModel(response.data.createCompany.company, Company)
  }

  /**
   * *@inheritDoc
   */
  public async isCompanyEmailAvailable (email: string): Promise<boolean> {
    const response = await this.graphQl.call('isCompanyEmailAvailable', { email })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return response.data.isCompanyEmailAvailable.is_email_available
  }

  /**
   * @inheritDoc
   */
  public async shortCreateCompany (companyInput: ShortCreateCompanyInput): Promise<TokenModel> {
    const shortCompany = new ShortCompanyCreateIntention(companyInput)
    const response = await this.graphQl.call('shortCreateCompany', { input: shortCompany.toRequest() })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].debugMessage ?? response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return await this.authControl.login({ email: companyInput.email, password: companyInput.password })
  }

  /**
   * @inheritDoc
   */
  public async updateCompany (companyInput: UpdateCompanyInput): Promise<CompanyModel> {
    const companyIntention = new CompanyUpdateIntention(companyInput)
    const response = await this.graphQl.call('updateCompany', { input: companyIntention.toRequest() })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return this.composeModel(response.data.updateCompany.company, Company)
  }
}
