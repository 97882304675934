
import { CartModel } from '../../../checkout/contracts'

import { OrderItem } from '../../molecules/OrdersListItem'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface IOrderRenewalService {
  /**
   * Adds provided order items to cart
   * @param order
   */
  renewCustomerOrder (order: OrderItem): Promise<CartModel>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const OrderRenewalServiceKey = Symbol.for('IOrderRenewalService')
