export interface ContactFormPayload {
  name: string
  email: string
  subject: string
  message: string
}

export interface IContactFormService {
  send (payload: ContactFormPayload): Promise<void>
}

export const ContactFormServiceType = Symbol.for('IContactFormService')
