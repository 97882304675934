
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface INipValidationService {
  validateCompanyVatId (vatId: string): Promise<CompanyValidationOutput>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CompanyValidationOutput {
  address: string
  city: string
  companyLegalName: string
  companyName: string
  country: string
  countryCode: string
  postalCode: string
  province: string
  valid: boolean
  vatNumber: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const NipValidationServiceType = Symbol.for('INipValidationService')
