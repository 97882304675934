import { IConnector, Injectable, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors, resolveFromStatus } from '../../../../support'

import { ContactFormPayload, IContactFormService } from './contracts'

@Injectable()
export class ContactFormService implements IContactFormService {
  private connector: IConnector

  constructor (connector: IConnector) {
    this.connector = connector
  }

  /**
   * @inheritDoc
   */
  public async send (payload: ContactFormPayload): Promise<void> {
    const response: IResponse = await this.connector.call(
      'contactForm',
      'send',
      {},
      payload,
      { 'Access-Control-Allow-Origin': '*' }
    )

    if (response && response.data.message) {
      throw new ResourceActionFailed(
        response.data.message,
        ConnectorErrors.Validation,
        response.data
      )
    }

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.data.message,
        resolveFromStatus(response.status),
        {}
      )
    }
  }
}
