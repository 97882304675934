import { AnyObject, Injectable } from '@movecloser/front-core'

import { CustomerSupportData, CustomerSupportRegistryData, ICustomerSupportService } from './customer-support.contracts'
import { ISiteService, SitePropertyTypes } from '../../../../contexts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class CustomerSupportService implements ICustomerSupportService {
  private regionsMap: CustomerSupportRegistryData[]

  constructor (private readonly siteService: ISiteService) {
    const regionsMap = this.siteService.getProperty('customerSupport')

    if (this.validateProperty(regionsMap)) {
      this.regionsMap = regionsMap.map(item => {
        const { email, firstName, lastName, phone, region_id: regionId } = item

        return { email, firstName, lastName, phone, regionId }
      })
    } else {
      this.regionsMap = []
    }
  }

  public getContactData (regionId: number | undefined): CustomerSupportData | null {
    const matchingRegion = this.regionsMap.find(item => item.regionId === regionId)

    if (matchingRegion) {
      return matchingRegion
    }

    return null
  }

  private validateProperty (value: SitePropertyTypes): value is AnyObject[] {
    return !!value && Array.isArray(value) && value.length > 0 && typeof value[0] === 'object'
  }
}
