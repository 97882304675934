import { ModulesUiRegistryEntry } from '../../../contexts'

import { resolveIframeModuleContent } from '../Iframe.helpers'

export const iframeModule: ModulesUiRegistryEntry = {
  component: () => import(
    /* webpackChunkName: "ui/core/iframe" */ './Iframe.ui.vue'
  ),
  resolver: resolveIframeModuleContent
}
