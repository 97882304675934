





import { Component, Mixins } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'

import { AUTH_GUARD_COMPONENT_CONFIG_MAP, AUTH_GUARD_COMPONENT_KEY } from './AuthGuard.config'
import { RouteNames as AuthRouteNames } from '../../../auth/routes'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<AuthGuard>({
  name: 'AuthGuard',
  created () {
    this.config = this.getComponentConfig(AUTH_GUARD_COMPONENT_KEY, AUTH_GUARD_COMPONENT_CONFIG_MAP)

    if (this.shouldRedirectToLogin) {
      this.$router.push({
        name: `auth.${AuthRouteNames.Auth}`
      })
    }
  }
})
export class AuthGuard extends Mixins(StructureConfigurable) {
  public get shouldRedirectToLogin (): boolean {
    return this.getConfigProperty<boolean>('shouldRedirectToLogin')
  }
}

export default AuthGuard
