import { Identifier } from '../../../../contexts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export interface UsageCalculatorPayload {
  input: {
    width: string
    length: string
    depth: string
  }
  productUsage: Record<string, string>
  total: Record<string, string>
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export interface IUsageCalculatorService {
  getPdf (payload: UsageCalculatorPayload, site?: Identifier | null, withoutPrices?: boolean): Promise<void>
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const UsageCalculatorServiceType = Symbol.for('IUsageCalculatorServiceType')
