/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const AUTH_GUARD_COMPONENT_KEY = 'AuthGuardComponent'

export interface AuthGuardConfig {
  shouldRedirectToLogin: boolean
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const AUTH_GUARD_COMPONENT_CONFIG_MAP: AuthGuardConfig = {
  shouldRedirectToLogin: false
}
