import { AnyObject } from '@movecloser/front-core'
import { SelectItem } from '../../../../dsl/atoms/Select'

export type ConsentItem = {
  option: string
  required: boolean
}

export type FormOptions = {
  [key: string]: SelectItem[] | ConsentItem[]
}

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export interface ISelenaFormService {
  send (formName: string, payload: AnyObject): Promise<void>
  getOptions(formName: string): Promise<FormOptions>
  getCertificates(): Promise<AnyObject[]>
  getWebinars (): Promise<AnyObject[]>
}

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export const SelenaFormServiceType = Symbol.for('ISelenaFormServiceType')
