// Copyright © 202w Move Closer

import { CurrencyConfig } from '@/shared/core/src/support/filters'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const extensions = {
  currency: {
    cropZeros: false,
    defaultCode: 'zł',
    currencyMap: {
      PLN: 'zł'
    }
  } as CurrencyConfig
}
