import { AnyObject } from '@movecloser/front-core'
import { FormOptions } from '../selenaForms'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface INewsletterService {
  subscribe (email:string): Promise<void>
  subscribeForm (form: AnyObject): Promise<void>
  getOccupations (): FormOptions
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const NewsletterServiceType = Symbol.for('INewsletterService')
