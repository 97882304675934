// Copyright © 2021 Move Closer

import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver'
import { IHttpConnectorConfig } from '@movecloser/front-core'

export const selenaFormsConnectorConfig = {
  certificates: () => {
    const defaultLocale = process.env.VUE_APP_HTTP_API_LOCALE || 'pl-PL'
    return new AxiosDriver({
      baseURL: process.env.VUE_APP_SELENA_FORMS_API_BASEURL,
      headers: {
        Accept: 'application/json',
        'Accept-Language': defaultLocale
      }
    }, (process.env.VUE_APP_DEBUG === 'true'))
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const http: IHttpConnectorConfig = {
  drivers: {
    ...selenaFormsConnectorConfig,
    api: () => {
      return new AxiosDriver({
        baseURL: process.env.VUE_APP_HTTP_API_BASEURL,
        headers: {
          Accept: 'application/json',
          'X-Client': process.env.VUE_APP_HTTP_API_CLIENT
        }
      }, process.env.VUE_APP_DEBUG_HTTP === 'true')
    }
  },
  default: 'api'
}
