// Copyright © 2021 Move Closer

import { MetaPropertyProperty } from 'vue-meta'

import { Identifier } from '../related'
import { SiteModel, SitePropertyTypes, SitesMap } from './data.contracts'
import { ImageProps } from '../../../dsl/atoms/Image'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export interface ISiteService {
  getActiveSite (): SiteModel
  getActiveSiteAddress (): string
  getActiveSiteBasePath (): string
  getActiveSiteLocale (): string
  getActiveSiteLogo (variant?: string): ImageProps | undefined
  getActiveSiteMeta (): MetaPropertyProperty[]
  getActiveSiteUrls (withAbsoluteUrls?: boolean): Record<string, string>
  getProperty (key: string): SitePropertyTypes
  getRelativeUrl (url: string): string
  getSites (): SiteModel[]
  getSiteById (id: Identifier): SiteModel | undefined
  getSiteBasePath (site: SiteModel): string
  setActive (site: SiteModel): void
  setSites (sites: SitesMap): void
  setProperty (key: string, payload: SitePropertyTypes): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteServiceType = Symbol.for('ISiteService')
