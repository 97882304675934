import {
  IConnector,
  Injectable,
  IResponse,
  ResourceActionFailed,
  ResponseType
} from '@movecloser/front-core'
import { saveAs } from 'file-saver'

import { resolveFromStatus } from '../../../../support'

import { IUsageCalculatorService, UsageCalculatorPayload } from './usage-calculator.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class UsageCalculatorService implements IUsageCalculatorService {
  private readonly connector: IConnector

  constructor (connector: IConnector) {
    this.connector = connector
  }

  public async getPdf (payload: UsageCalculatorPayload, site?: number, withoutPrices?: boolean): Promise<void> {
    const response: IResponse = await this.connector.call(
      'usageCalculator', 'getPdf', {},
      {
        ...payload,
        ...(site ? { site } : {}),
        ...(withoutPrices ? { withoutPrice: 1 } : {})
      },
      { accept: 'application/octet-stream' },
      ResponseType.Blob
    )

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors.message,
        resolveFromStatus(response),
        {}
      )
    }

    saveAs(response.data as Blob)
  }
}
