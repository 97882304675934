import { AbstractEvent } from '@kluseg/trackify/dist/main'
import { CustomAnalyticsEvent } from '@kluseg/trackify/dist/interfaces/analytics-event'

import { ClientUpdateConfig } from '../contracts'

export class ClientUpdateEvent extends AbstractEvent implements CustomAnalyticsEvent<ClientUpdateConfig> {
  name = 'custom.clientUpdate'

  constructor (protected config: ClientUpdateConfig) {
    super(config)
  }

  forDriver (driverName: string) {
    if (driverName === 'UsercomBrowserDriver') {
      return {
        event_name: 'client.update',
        event_payload: this.getData()
      }
    }
    return null
  }

  getData (): ClientUpdateConfig {
    return this.config
  }
}
