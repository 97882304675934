// Copyright © 202w Move Closer

import { AllowedAttributes } from '@core'

import { AttributeDriver } from '@/shared/core/src/front/products/organisms/ProductCard/ProductCard.config'
import { BadgeShape } from '@/shared/core/src/dsl/atoms/Badge'
import { ComponentsStructureConfig } from '@/shared/core/src/support/mixins'
import { FiltersDrawerMode } from '@/shared/core/src/front/shared/molecules/FiltersDrawer/FiltersDrawer.config'
import { RecommendationAction } from '@/shared/core/src/front/products/contracts/repositories'
import { ShapeMap, SizeMap, VariantMap } from '@/shared/core/src/dsl/composables'
import { Theme, Variant } from '@/shared/core/src/dsl/atoms/Button'
import { FilterLayout } from '@/shared/core/src/front/shared/organisms/Filters/Filters.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 *
 */
export const configuration: ComponentsStructureConfig = {
  structure: {
    AccountView: {
      processingOrderStatus: 'pendingPayment',
      ongoingShipmentStatus: 'complete',
      redirectFromAccountPage: 'profile.me.profile',
      redirectOnDesktop: true
    },
    AddedToCartModalComponent: {
      action: RecommendationAction.BrandAndCategory,
      layout: 'rich',
      suggestedProductsCount: {
        desktop: 3,
        mobile: 2
      }
    },
    //
    // Global strategy for auth.
    authActionStrategy: 'router',
    //
    //
    AuthGuardComponent: {
      shouldRedirectToLogin: true
    },
    AuthView: {
      loginRegisterContext: 'headingSwitch'
    },
    BuyerContextSwitchComponent: {
      acceptPrivacyForLoggedInUser: true,
      loginRegisterContextComponent: 'headingSwitch'
    },
    CartCouponsComponent: {
      errorIcon: 'Error',
      trashIcon: 'Trash'
    },
    CartItemComponent: {
      layout: 'basic',
      shouldLinkCategory: false,
      showNettPrice: true
    },
    CartSummaryComponent: {
      changedOverall: true,
      couponsLocation: 'bottom',
      shouldDisplayTotalOnMobile: true,
      showCoupons: true,
      showBenefits: false,
      showShippingPreview: true,
      showFreeDeliveryProgress: false,
      showTitle: true,
      showTaxes: false,
      showNetSubtotal: true,
      showCartIcon: true
    },
    CartView: {
      layout: 'enhanced',
      cartIcons: [
        'ApplePay', 'Blik', 'GooglePay', 'MasterCard', 'Pay24Now', 'PayPo', 'Przelewy24', 'Visa'
      ],
      showPaymentsMethodsLabel: false,
      shouldLoadGifts: true,
      shouldLoadSamples: true,
      shouldLoadSuggestedProducts: true,
      carouselConfig: {
        autoplay: false,
        centralize: false,
        delay: 2000,
        hasPagination: false,
        hasButtons: true,
        initialSlide: 0,
        loop: false,
        pauseOnHover: false,
        responsive: {
          mobile: { perPage: 2.3 },
          desktop: { perPage: 4 }
        },
        spaceBetween: 24
      }
    },
    ChangeAddressFormFields: {
      shouldChangePostalCodeLayout: true,
      shouldHaveCountryForm: true,
      shouldLoadShipmentRegions: true
    },
    CheckoutOverview: {
      consents: [
        {
          option: 'acceptContent',
          hasDescription: false,
          required: true
        },
        {
          option: 'acceptPrivacy',
          hasDescription: false,
          required: true
        }
      ],
      shouldDisplayAgreementsWarning: true,
      showRodo: false,
      shippingIcons: {
        flatrate: null
      }
    },
    CheckoutView: {
      componentName: 'CheckoutTwo',
      displayFreeDeliveryValue: true,
      displayProducts: false,
      hasRegionField: true,
      requireRegionFieldInPayload: true,
      shouldHaveBrandsWrapper: false,
      summaryHeading: 'yourOrder',
      truckIcon: 'TruckMoving'
    },
    ChangeAddressForm: {
      hasSavedAddresses: false,
      isDefaultEditing: true,
      shouldBeFluid: true,
      validators: {
        company: 'filled|string|min:2',
        countryCode: 'required|string|min:2',
        city: 'required|string|min:3',
        firstName: 'required|string|min:3',
        lastName: 'required|string|min:3',
        postalCode: 'required|string',
        phoneNumber: 'required|string|min:9',
        street: 'required|string|min:3',
        region: 'required'
      }
    },
    ChangeSubscriptionsPreferencesForm: {},
    CircularStepperComponent: {
      steps: [
        {
          type: 'data',
          version: 'validate',
          auth: false
        },
        {
          type: 'shipping',
          version: 'withPicker',
          auth: false
        },
        {
          type: 'payments',
          version: 'general',
          auth: false
        }
      ],
      hiddenStepsIndices: ['1']
    },
    ConsentsRequestView: {
      consents: [
        {
          option: 'acceptContent',
          hasDescription: false,
          required: true
        },
        {
          option: 'emailOffers',
          hasDescription: true,
          required: false
        },
        {
          option: 'smsOffers',
          hasDescription: true,
          required: false
        }
      ],
      hasResellerIdField: true,
      validatorsMap: {
        company: {
          acceptContent: 'accepted',
          vatTaxId: 'required|string'
        },
        guest: {
          acceptContent: 'accepted'
        }
      }
    },
    ContactFormComponent: {
      consents: [
        {
          option: 'acceptPrivacy',
          hasDescription: false,
          required: true
        }
      ],
      subjects: [
        { value: 'Moje zamówienie', label: 'Moje zamówienie' },
        { value: 'Zwrot/reklamacja', label: 'Zwrot/reklamacja' },
        { value: 'Pytanie o produkt', label: 'Pytanie o produkt' },
        { value: 'Współpraca handlowa, marketingowa', label: 'Współpraca handlowa, marketingowa' },
        { value: 'Sprawy HR', label: 'Sprawy HR' },
        { value: 'Inne', label: 'Inne' }
      ],
      input: {
        animatedLabel: true
      },
      showRodo: false
    },
    ConsentComponent: {
      useAccordion: true
    },
    CustomerDetailsStep: {
      guestDetailsFieldsetType: 'companySimplified',
      hasRegionField: true,
      shouldDisplayCompanyNameField: false,
      submitLabel: 'registerAccount',
      withRegistrationFields: true,
      withCompanyCheckboxFields: true,
      withSocials: true,
      validators: {
        company: {
          vatId: 'required|string',
          email: 'required|string|email',
          password: 'required|string|min:6',
          passwordConfirmation: 'required|string|min:6',
        },
        guest: {
          address: 'required|string|min:4',
          acceptContent: 'accepted',
          acceptPrivacy: 'accepted',
          city: 'required|string|min:2',
          country: 'required|string|min:2',
          region: 'required',
          email: 'required|email',
          firstName: 'required|string|min:3',
          isSignupRequested: 'boolean',
          lastName: 'required|string|min:2',
          password: 'required_if:isSignupRequested,true|string|min:6|confirmed',
          passwordConfirmation: 'nullable',
          phone: 'required|string|min:9',
          postalCode: 'required|string'
        },
        sso: {
          acceptContent: 'accepted',
          vatId: 'required|string'
        }
      }
    },
    CompanyCreateView: {
      consents: [
        {
          option: 'acceptPrivacy',
          hasDescription: true,
          required: true
        },
        {
          option: 'acceptContent',
          hasDescription: true,
          required: true
        },
        {
          option: 'consent1',
          hasDescription: true,
          required: false
        },
        {
          option: 'consent2',
          hasDescription: true,
          required: false
        },
        {
          option: 'consent3',
          hasDescription: true,
          required: false
        },
        {
          option: 'consent4',
          hasDescription: true,
          required: false
        }
      ],
      consentsDescription: {
        acceptContent: 'acceptContent',
        acceptPrivacy: 'acceptPrivacy',
        consent1: 'smsOffers',
        consent2: 'emailOffers',
        consent3: 'directMarketing',
        consent4: 'partnersOffers'
      },
      companyCreateValidationsMap: {
        adminEmail: 'required|string|email',
        adminFirstName: 'required|string',
        adminLastName: 'required|string',
        companyEmail: 'required|string|email',
        street: 'required',
        city: 'required',
        region: 'required',
        postCode: 'required',
        countryCode: 'required',
        telephone: 'required',
        legalName: 'required|string',
        companyName: 'required|string',
        vatTaxId: 'required|string',
        acceptContent: 'accepted',
        acceptPrivacy: 'accepted'
      }
    },
    DeliveryDetailsStepComponent: {
      input: {
        hasVisibleLabel: false
      },
      contextSwitchComponent: 'tabsSwitch',
      contextSwitchComponentProps: {
        tabs: {
          active: 'shipOnBilling',
          tabs: [
            {
              active: true,
              isDisabled: false,
              label: 'shipOnBilling',
              id: 'shipOnBilling'
            },
            {
              active: false,
              isDisabled: false,
              label: 'shipOnOther',
              id: 'shipOnOther'
            }
          ],
          isWrapped: true
        }
      },
      hasTextareaHeading: true,
      hasRegionField: true,
      hasCountryField: true,
      isAlwaysCompany: true,
      withCompanyCheckboxFields: true,
      validatorsMap: {
        address: 'required|string|min:4',
        acceptContent: 'accepted',
        billingAddress: 'required_if:sameBillingAddress,false|string|min:4',
        billingCity: 'required_if:sameBillingAddress,false|string|min:2',
        billingPostalCode: 'required_if:sameBillingAddress,false|string',
        city: 'required|string|min:2',
        company: 'filled|string|min:2',
        country: 'required|string|min:2',
        email: 'required|email',
        firstName: 'required|string|min:3',
        hasInvoice: 'boolean',
        invoiceCompany: 'required_if:hasInvoice,true|string|min:2',
        invoiceNip: 'required_if:hasInvoice,true|string|min:10|max:10',
        isSignupRequested: 'boolean',
        lastName: 'required|string|min:2',
        password: 'required_if:isSignupRequested,true|string|min:6|confirmed',
        passwordConfirmation: 'nullable',
        phone: 'required|string|min:9',
        postalCode: 'required|string',
        region: 'required',
        sameBillingAddress: 'boolean'
      }
    },
    FilterBadges: {
      closeIcon: 'Close',
      hasClearAllIcon: true
    },
    Filters: {
      icons: {
        accordionIcon: 'CaretDown',
        closeIcon: 'Close'
      },
      layout: FilterLayout.Accordion,
      withCount: false,
      withModeSwitch: true,
      withVisibilityToggle: false
    },
    FiltersDrawer: {
      hasCategoryTitle: true,
      mode: FiltersDrawerMode.InstantApply,
      shouldRenderEmptyState: false
    },
    FiltersWrapperComponent: {
      filterIcon: 'FiltersTrigger',
      shouldAnimate: true,
      shouldImplementMobileHeading: true
    },
    FooterComponent: {
      contactNavAsAccordion: true,
      shouldDisplayCopyrightsInSimple: true,
      shouldDisplaySiteAuthorInSimple: true,
      shouldDisplaySocialNav: false,
      shouldLoadTrusted: false
    },
    FooterModule: {
      hasAdditionalContent: true
    },
    GalleryComponent: {
      closeIcon: 'Close'
    },
    GuestDetailsFormFieldsetComponent: {
      hasSSOContextSwitcher: true
    },
    HeroModule: {
      autoplay: false,
      delay: 5000,
      hasBenefitsBar: false
    },
    InPostModalComponent: {
      isSearchByPostcode: false,
      showPaymentOptions: false
    },
    LoginFormComponent: {
      hasLeadText: true,
      form: {
        hasLabel: true,
        reverseOrder: true
      },
      input: {
        animatedLabel: true
      },
      password: {
        simple: false
      },
      reverseOrder: true,
      socials: {
        display: true, // todo: sso
        simple: false,
        align: 'column'
      },
      isAlertUnderForm: true
    },
    MegaMenuComponent: {
      hasAdditionalContent: true,
      hasPagination: true,
      hasAdditionalContainer: true,
      navCategoryItemAsHeading: false
    },
    NavbarModule: {
      applyScrollBackground: true,
      type: 'doubleNavAndLanguages',
      useDrawer: false,
      shouldHideMobileNav: false,
      showLanguageSwitcher: false,
      showDynamicResults: true,
      shouldUseManyLogos: true,
      shouldSwitchLogoWhenPremium: false,
      isNameVisible: true,
      loginStrategy: 'router',
      cartStrategy: 'router',
      menuOpeningStrategyOnTablet: 'icon',
      staticLinkIcons: [
        {
          id: 'account',
          order: 1,
          icon: 'User'
        },
        {
          order: 2,
          id: 'favouriteProducts',
          icon: 'Heart'
        }
      ],
      showCartIconWithoutPrice: true,
      mobileNavIcons: [
        {
          id: 'home',
          label: 'home',
          order: 1,
          icon: {
            default: 'HouseLine',
            active: 'HouseLine'
          }
        },
        {
          id: 'menuWithSearch',
          label: 'menuWithSearch',
          order: 2,
          icon: {
            default: 'SearchWithLines',
            active: 'Close'
          }
        },
        {
          id: 'cart',
          label: 'cart',
          order: 3,
          icon: {
            default: 'ShoppingCartFull',
            active: 'ShoppingCartFull'
          }
        },
        {
          id: 'account',
          label: 'account',
          order: 4,
          icon: {
            default: 'User',
            active: 'Close'
          }
        }
      ],
      possibleStaticLinks: {
        MyAccount: {
          link: 'account'
        }
      },
      useSecondAuthDrawer: true
    },
    NewsletterModule: {
      alertButtonTheme: 'secondary',
      buttonHasLabel: false,
      buttonIcon: '',
      buttonTheme: 'primary',
      consents: [
        {
          option: 'consent1',
          hasDescription: true,
          required: true
        },
        {
          option: 'consent2',
          hasDescription: true,
          required: true
        },
        {
          option: 'consent3',
          hasDescription: true,
          required: true
        },
        {
          option: 'consent4',
          hasDescription: true,
          required: true
        },
        {
          option: 'consent5',
          hasDescription: true,
          required: true
        },
      ],
      consentsDescription: {
        consent1: 'generalFormConsent',
        consent2: 'smsOffers',
        consent3: 'emailOffers',
        consent4: 'directMarketing',
        consent5: 'partnersOffers',
      },
      layout: 'extended',
      useExtendedConsents: true
    },
    OrdersListItemComponent: {
      daysForPaymentRetry: 7,
      orderAgainButton: true,
      layout: 'default',
      theme: 'primary',
      variant: 'outline',
      orderAgainButtonTheme: 'primary',
      showOrderAgainSuccessInfo: true,
      showOrderStatus: false,
      orderAgainButtonVariant: 'full',
      hasIterator: true,
      summaryVariant: 'Vertical',
      actionVariant: 'Extended',
      showPaymentAmount: false,
      headerDecorator: 'accent',
      addressVariant: 'single',
      useServiceDefinedLinkForEmpty: true
    },
    OrdersView: {
      hasMargin: false
    },
    PaymentStep: {
      drivers: [
        { code: 'przelewy24', methodId: '154' }, // BLIK
        'przelewy24',
        { code: 'przelewy24', methodId: '147' },
        { code: 'przelewy24', methodId: '227' },
        { code: 'przelewy24', methodId: '239' },
        { code: 'przelewy24', methodId: '238' },
        // { code: 'przelewy24', methodId: '178' }, // przelew tradycyjny
        'stripe_payments_checkout',
        'banktransfer',
        'proforma',
        'delayedpayment',
        'cashondelivery',
        'companycredit'
      ],
      icons: {
        banktransfer: '/assets/images/payments/przelew.png',
        przelewy24: '/assets/images/payments/przelewy24.svg',
        przelewy24_blik: '/assets/images/payments/blik.svg',
        przelewy24_card: '/assets/images/payments/credit-card.svg',
        przelewy24_apple_pay: '/assets/images/payments/apple_pay.svg',
        przelewy24_google_pay: '/assets/images/payments/google_pay.svg',
        przelewy24_pay_po: '/assets/images/payPo.svg',
        stripe_payments_checkout: '/assets/images/payments/stripe-logo.svg'
      }
    },
    PrivacyModal: {
      defaultChecked: false,
      hasPrimaryBtn: true
    },
    ProductCard: {
      additionalAttributes: [],
      addToCartProps: { theme: Theme.Primary, variant: Variant.Full },
      badgeRepository: {
        [AllowedAttributes.IsNew]: { driver: AttributeDriver.Badge, shape: BadgeShape.Rectangle },
        [AllowedAttributes.IsBestseller]: { driver: AttributeDriver.Badge, shape: BadgeShape.Rectangle }
      },
      displayMainCategoryLogo: true,
      mainAttributes: [AllowedAttributes.IsNew, AllowedAttributes.IsBestseller],
      highlightedAttributes: [AllowedAttributes.Capacity],
      modalSize: 'large',
      productCardType: 'rich',
      shouldDisplayNetto: true,
      useDrawer: true,
      withAddToCart: true,
      withGoToProduct: true,
      withRating: false
    },
    ProductHeaderComponent: {
      badgeRegistry: {
        default: {
          theme: 'default',
          shape: ShapeMap.Rectangle,
          variant: VariantMap.Outline,
          size: SizeMap.Medium
        }
      },
      badgesOnGallery: false,
      descriptionAttribute: 'type',
      displayMainCategoryLogo: true,
      doubleCheckStockStatus: true,
      favouriteAsIcon: true,
      highlightedAttributes: [],
      listedAttributes: ['sellableQuantity', 'capacity', 'package_amount', 'performance'],
      shopOnlyAttributes: ['sellableQuantity'],
      hasBenefitsBar: false,
      hasDeliveryTimer: false,
      hasGiftsBox: false,
      hasQuantity: true,
      hasPriceInAddToCart: false,
      hasRating: false,
      hasNotificationForm: false,
      hasOrderDetails: true,
      icons: {
        addToCartButtonIcon: null,
        heartEmptyIcon: 'Heart',
        heartFullIcon: 'Heart',
        infoIcon: 'Info',
        notifyButtonIcon: 'Envelope'
      },
      modalSize: 'large',
      promotionBadgeHasLabel: true,
      shouldHaveSeparatedRating: false,
      useDrawer: true,
      useVendorReviews: false,
      variantSwitcherShowChosen: true,
      shouldHaveVisibleRating: false,
      showSingleVariantRating: false,
      shouldDisplayDiscount: true,
      shouldDisplayNetto: true,
      isNewBadgeSquare: false,
      shouldDisplayRegularPriceForDiscount: true,
      shouldDisplaySimplePrice: true,
      shouldDisplayUnits: false,
      shouldDisplayOmnibus: false,
      orderDetails: { delivery: 'Truck' }
    },
    ProductsListComponent: {
      hasCartSummaryBar: true,
      hasCategoryNavigation: true,
      listDisplayControlsInDrawer: false,
      openFilters: ['type'],
      paginationHasDirectionButtons: false,
      paginationHasGroupedLayout: false,
      shouldImplementMobileHeading: false
    },
    ProductsTeaserComponent: {
      carouselConfig: {
        autoplay: false,
        responsive: {
          mobile: {
            perPage: 1.3,
            inLoop: true,
            variableWidth: true,
            hasButtons: false,
            useTransform: true
          },
          desktop: {
            perPage: 5,
            inLoop: false,
            variableWidth: false,
            useTransform: true
          }
        }
      }
    },
    ProductsUpsellComponent: {
      carouselConfig: {
        autoplay: false,
        responsive: {
          mobile: {
            perPage: 1,
            inLoop: true,
            variableWidth: true,
            hasButtons: false,
            useTransform: true
          },
          desktop: {
            perPage: 4,
            inLoop: false,
            variableWidth: false,
            useTransform: true
          }
        }
      }
    },
    ProductReviewsModule: {
      useVendorReviews: true
    },
    ProfileWrapperComponent: {
      hasContainerClass: true,
      hasWelcomeHeading: false,
      hasTabs: false,
      shouldCenterNavigationElements: true,
      containerListClass: [],
      containerClass: 'container'
    },
    ProfileView: {
      layout: 'tabsData',
      myDataContentTypes: ['email', 'firstName', 'lastName'],
      profileNavTabsConfig: {
        account: {
          active: false,
          order: 20,
          components: [
            {
              name: 'ChangeAddressFormConfigurable',
              props: {
                isAlwaysCompany: {
                  value: true
                },
                displayFormHeading: {
                  value: false
                },
                formHeadingProp: {
                  value: 'formHeadingProp',
                  useTranslations: true
                },
                formEditLabelProp: {
                  value: 'formEditLabelProp',
                  useTranslations: true
                },
                addressType: {
                  value: 'billing'
                },
                hasRegionField: {
                  value: true
                },
                simpleFormValidate: {
                  value: true
                },
                defaultAddress: {
                  value: true
                },
                showButtons: {
                  value: false
                },
                fieldsFixedDisabled: {
                  value: []
                },
                showCompanyFields: {
                  value: false
                }
              }
            }
          ],
          isDisabled: false
        },
        address: {
          active: false,
          order: 30,
          components: [
            {
              name: 'ChangeAddressForm',
              props: {
                isAlwaysCompany: {
                  value: true
                },
                headingProp: {
                  value: 'headingProp',
                  useTranslations: true
                },
                hasRegionField: {
                  value: true
                },
                alwaysInEditMode: {
                  value: true
                },
                hideLabelOnEdit: {
                  value: true
                },
                showCompanyFields: {
                  value: false
                }
              }
            }
          ],
          isDisabled: false
        },
        baseSettings: {
          active: true,
          order: 10,
          components: [
            {
              name: 'ChangeEmailForm',
              props: {
                formHeadingProp: {
                  value: 'formHeadingProp',
                  useTranslations: true
                },
                formEditLabelProp: {
                  value: 'formEditLabelProp',
                  useTranslations: true
                }
              }
            },
            {
              name: 'ChangePasswordForm',
              props: {
                formHeadingProp: {
                  value: 'formHeadingProp',
                  useTranslations: true
                },
                formEditLabelProp: {
                  value: 'formEditLabelProp',
                  useTranslations: true
                }
              }
            }
          ],
          isDisabled: false
        },
        company: {
          active: false,
          order: 40,
          components: [
            {
              name: 'ChangeCompanyDataForm',
              props: {}
            }
          ],
          isDisabled: false
        },
        notification: {
          active: false,
          order: 50,
          components: [
            {
              name: 'ChangeSubscriptionsPreferencesForm',
              props: {
                providerProp: {
                  value: 'getresponse'
                },
                formDataProp: {
                  value: {
                    isNewsletterSubscribed: {
                      id: 'isNewsletterSubscribed',
                      isRequired: false,
                      defaultValue: false,
                      fieldType: 'UiCheck'
                    },
                    isSmsSubscribed: {
                      id: 'isSmsSubscribed',
                      isRequired: false,
                      defaultValue: false,
                      fieldType: 'UiCheck'
                    }
                  }
                },
                consents: {
                  value: [
                    {
                      option: 'acceptPrivacy',
                      hasDescription: true,
                      disabled: true,
                      required: true,
                      value: true
                    },
                    {
                      option: 'acceptContent',
                      hasDescription: true,
                      disabled: true,
                      required: true,
                      value: true
                    },
                    {
                      option: 'consent1',
                      hasDescription: true,
                      required: false
                    },
                    {
                      option: 'consent2',
                      hasDescription: true,
                      required: false
                    },
                    {
                      option: 'consent3',
                      hasDescription: true,
                      required: false
                    },
                    {
                      option: 'consent4',
                      hasDescription: true,
                      required: false
                    }
                  ],
                  useTranslations: false
                },
                consentsDescription: {
                  value: {
                    acceptContent: 'acceptContent',
                    acceptPrivacy: 'acceptPrivacy',
                    consent1: 'smsOffers',
                    consent2: 'emailOffers',
                    consent3: 'directMarketing',
                    consent4: 'partnersOffers'
                  },
                  useTranslations: false
                },
                submitCtaLabel: {
                  value: 'submitCtaLabel',
                  useTranslations: true
                },
                cancelCtaLabel: {
                  value: 'cancelCtaLabel',
                  useTranslations: true
                },
                renderClaim: {
                  value: true
                },
                headingProp: {
                  value: 'headingProp',
                  useTranslations: true
                }
              }
            }
          ],
          isDisabled: false
        }
      }
    },
    RateSummaryComponent: {
      omnibusDetailsUrl: 'https://help.etrusted.com/hc/pl/articles/4419944605341'
    },
    RequestCompanyFormComponent: {
      validatorsMap: {
        existingCompany: {
          email: 'required|string|email',
          vatTaxId: 'required|string'
        },
        newCompany: {
          adminEmail: 'required|string|email',
          adminFirstName: 'required|string',
          adminLastName: 'required|string',
          city: 'required|string',
          companyEmail: 'required|string|email',
          countryCode: 'required|string',
          legalName: 'required|string',
          companyName: 'required|string',
          postCode: 'required|string',
          region: 'required',
          vatTaxId: 'required|string',
          street: 'required|string',
          telephone: 'required|string'
        }
      }
    },
    RequestResetLinkFormComponent: {
      backIcon: 'ArrowLeft',
      buttonVariant: 'outline',
      isAlertUnderForm: true
    },
    ResetPasswordFormComponent: {
      buttonVariant: 'outline',
      onSuccessStrategy: 'banner',
      subtitle: true,
      password: {
        simple: false
      },
      input: {
        animatedLabel: true
      }
    },
    ReviewsTeaser: {
      carouselConfig: {
        autoplay: false,
        hasPagination: false,
        responsive: {
          mobile: {
            perPage: 1.2,
            variableWidth: true,
            hasButtons: false
          },
          desktop: {
            perPage: 3.5,
            variableWidth: false
          }
        }
      },
      reviewsPeriod: '365days'
    },
    SearchResultsView: {
      hasControls: true,
      resultsPerPage: 80,
      usePerPageParam: false,
      useRouteParams: true
    },
    SearchResultsComponent: {
      calculateOffsetForNavbar: true,
      searchInputClosable: false,
      searchInputOpenOnStart: true,
      searchInputHasButton: true,
      hasCloseIcon: true,
      hasSearchHints: true,
      shouldRelyOnIsOpen: true,
      shouldSetResultsPosition: true,
      sliceResults: 3,
      shouldWatchResize: true
    },
    SearchResultProductItemComponent: {
      useEnhancedTranslator: true
    },
    ShippingsComponent: {
      shippingIcons: {
        flatrate: null
      },
      usePriceIncludingTaxForMethod: true
    },
    ShippingStepComponent: {
      shippingIcons: {
        flatrate: null
      }
    },
    SignupFormComponent: {
      variant: 'companyValidate',
      consents: {
        group: true,
        options: [
          {
            option: 'acceptContent',
            hasDescription: false,
            required: true
          },
          {
            option: 'acceptPrivacy',
            hasDescription: false,
            required: true
          },
          {
            option: 'emailOffers',
            hasDescription: true,
            required: false
          }
        ]
      },
      isAlertUnderForm: true,
      socials: {
        display: true
      },
      validatorsMap: {
        company: {
          acceptContent: 'accepted',
          acceptPrivacy: 'accepted',
          vatId: 'required|string',
          email: 'required|string|email',
          password: 'required|string|min:6',
          passwordConfirmation: 'required|string|min:6',
        },
        guest: {
          acceptContent: 'accepted',
          acceptPrivacy: 'accepted',
          email: 'required|email',
          fistName: 'required|string',
          lastName: 'required|string',
          password: 'required|string|min:6',
          passwordConfirmation: 'required|string|min:6',
        }
      }
    },
    Shippings: {
      template: {
        withPlus: false
      },
      useTemplateDecorator: false
    },
    SignUpWelcome: {
      buttonVariant: 'outline'
    },
    SimpleForm: {
      layout: 'toggleable'
    },
    SingleCartItemComponent: {
      iterator: true
    },
    QuantityPickerComponent: {
      edgeQuantity: 0,
      minusIcon: 'Minus',
      plusIcon: 'Plus'
    },
    ThankYouComponent: {
      createAccountStrategy: 'router'
    }
  },
  options: {
    HeadingModuleForm: {
      decorators: ['default', 'accent']
    }
  },
  modules: {
    navLinks: {
      loyaltyTabs: [
        {
          label: 'benefitPrograms',
          priority: 50
        }
      ],
      profileTabs: [
        {
          label: 'me',
          priority: 100
        },
        {
          label: 'addressesBillings',
          priority: 80
        },
        {
          label: 'myData',
          priority: 70
        },
        {
          label: 'newsletter',
          priority: 60
        }
      ],
      orderTabs: [
        {
          label: 'historyOfOrders',
          priority: 90
        }
      ],
      productTabs: [],
      wishlistTabs: []
    },
    possibleOrderStatuses: [
      'oexWKompletacji',
      'processing',
      'oexWydane',
      'fraud',
      'pendingPayment',
      'paymentReview',
      'pending',
      'banktransfer',
      'holded',
      'complete',
      'oexNiedoreczone',
      'closed',
      'canceled',
      'pendingPaypal',
      'paypalReversed',
      'paypalCanceledReversal'
    ],
    highlightedOrderStatuses: [
      'canceled', 'holded', 'fraud'
    ],
    retryOrderStatuses: [
      'paymentReview'
    ],
    returnOrderStatuses: [
      'complete'
    ]
  }
}
