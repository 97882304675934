import { ModulesUiRegistryEntry } from '../../../contexts'

import { resolveProductsSetModuleContent } from '../ProductsSet.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const productsSetModule: ModulesUiRegistryEntry = {
  component: () => import(
    /* webpackChunkName: "ui/core/product-reviews" */ './ProductsSet.ui.vue'),
  resolver: resolveProductsSetModuleContent
}
