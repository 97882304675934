import { AbstractEvent } from '@kluseg/trackify/dist/main'
import { CustomAnalyticsEvent } from '@kluseg/trackify/dist/interfaces/analytics-event'

import { ContactConfig } from '../contracts'

export class ContactEvent extends AbstractEvent implements CustomAnalyticsEvent<ContactConfig> {
  name = 'custom.contact'

  constructor (protected config: ContactConfig) {
    super(config)
  }

  forDriver (driverName: string) {
    if (driverName === 'SyneriseBrowserDriver') {
      return {
        event_name: 'contact.form',
        event_payload: this.getData(),
        event_type: 'form',
        event_mapping: {
          email: 'email',
          newsletterAgreement: 'emailOffers',
          smsAgreement: 'smsOffers',
          subject: 'subject',
          message: 'message',
          name: 'name',
          acceptPrivacy: 'acceptPrivacy'
          // smsOffers: boolean
        }
      }
    } else if (driverName === 'UsercomBrowserDriver') {
      return null
    //   return {
    //     event_name: 'event.contact',
    //     event_payload: this.getData(),
    //     event_type: 'form'
    //   }
    }
    return null
  }

  getData (): ContactConfig {
    return { ...this.config }
  }
}
