import { Injectable, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../../support'

import { ICartService } from '../../../checkout/services/cart'
import { CartModel } from '../../../checkout/contracts'

import { OrderItem } from '../../molecules/OrdersListItem'

import { IOrderRenewalService } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class OrderRenewalService implements IOrderRenewalService {
  protected readonly cartService: ICartService
  protected readonly localStorage: Storage
  protected readonly cartId: string
  protected booted: boolean = false

  constructor (cartService: ICartService, localStorage: Storage, cartId: string) {
    this.cartService = cartService
    this.localStorage = localStorage
    this.cartId = cartId

    this.boot()
  }

  /**
   * @inheritDoc
   */
  public async renewCustomerOrder (order: OrderItem): Promise<CartModel> {
    if (!this.booted) {
      throw new ResourceActionFailed(
        '[OrderRenewalService] Could not perform request on not booted service!',
        resolveFromStatus(404),
        {}
      )
    }

    const candidateItems = order.products?.map((product) => {
      return {
        sku: product.sku,
        quantity: product.quantity
      }
    })

    const storageCartId = this.cartService.getCartId()

    if (!storageCartId || typeof storageCartId === 'undefined') {
      throw new ResourceActionFailed(
        'There is no valid localStorage `cartId` to perform request',
        resolveFromStatus(404),
        {}
      )
    }

    return await this.cartService.addManyToCart(storageCartId, candidateItems ?? [])
  }

  private boot () {
    if (typeof this.cartService === 'undefined') {
      throw new Error('[OrderRenewalService]: `cartService` is not defined!')
    }

    this.booted = true
  }
}
