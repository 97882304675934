import { AsyncComponent } from 'vue'

import { AllowedAttributes } from '../../../../contexts'
import { BadgeShape } from '../../../../dsl/atoms/Badge'
import { VariantMap } from '../../../../dsl/composables'
import { ButtonProps, Theme, Variant } from '../../../../dsl/atoms/Button'

import { AttributeData } from './ProductCard.contracts'

export enum AttributeDriver {
  Icon = 'icon',
  Badge = 'badge'
}

export enum ProductCardType {
  Buyable = 'buyable',
  Default = 'default',
  Rich = 'rich'
}

export const attributesBadgeRepository: Record<string, AttributeData> = {
  [AllowedAttributes.Application]: { icon: 'DayNightIcon', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.CertFSC]: { icon: 'ArrowLeftIcon', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.CertVegan]: { icon: 'ArrowDownIcon', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.CertCrueltyFree]: { icon: 'ArrowTopIcon', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.IsNew]: { icon: 'ArrowTopIcon', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.IsNatural]: { icon: 'Leaf', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.HasFreeDelivery]: { icon: 'FreeDelivery', driver: AttributeDriver.Icon, shape: BadgeShape.Square },
  [AllowedAttributes.IsSale]: { driver: AttributeDriver.Badge, shape: BadgeShape.Square },
  [AllowedAttributes.HasGift]: { driver: AttributeDriver.Badge, shape: BadgeShape.Rectangle },
  [AllowedAttributes.IsSponsored]: { driver: AttributeDriver.Badge, shape: BadgeShape.Rectangle },
  default: { driver: AttributeDriver.Badge, shape: BadgeShape.Square }
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const productCardTypeRegistry: Record<string, AsyncComponent> = {
  [ProductCardType.Buyable]: () => import(/* webpackChunkName: "products/ProductCardBuyable" */ './versions/ProductCardBuyable.vue'),
  [ProductCardType.Default]: () => import(/* webpackChunkName: "products/ProductCardDefault" */ './versions/ProductCardDefault.vue'),
  [ProductCardType.Rich]: () => import(/* webpackChunkName: "products/ProductCardRich" */ './versions/ProductCardRich.vue')
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PRODUCT_CARD_COMPONENT_KEY = 'ProductCard'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface ProductCardConfig {
  additionalAttributes: string[]
  addToCartProps: Partial<ButtonProps>
  badgeRepository: Record<string, AttributeData>
  badgeTheme: string // todo: aelia - sprawdzic
  badgeVariant: string // todo: aelia - sprawdzic
  disabledBadgeIcons: string[] // todo: aelia - sprawdzic
  displayMainCategoryLogo: boolean
  goToProductProps: Partial<ButtonProps>
  mainAttributes: string[]
  modalSize: string
  productCardType: string
  shouldDisplayNetto: boolean
  useDrawer: boolean
  themeOfNewBadge: string
  withAddToCart: boolean
  withGoToProduct: boolean
  withRating: boolean
}

/**
 * Component configuration for app
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const PRODUCT_CARD_DEFAULT_CONFIG: ProductCardConfig = {
  additionalAttributes: ['gift', 'sponsored'],
  addToCartProps: { theme: Theme.Primary, variant: Variant.Outline },
  badgeTheme: '',
  badgeRepository: attributesBadgeRepository,
  badgeVariant: VariantMap.Full,
  disabledBadgeIcons: [],
  displayMainCategoryLogo: false,
  goToProductProps: { theme: Theme.Primary, variant: Variant.Outline },
  mainAttributes: ['isNew', 'isBestseller', 'isSale'],
  modalSize: 'medium',
  productCardType: 'default',
  shouldDisplayNetto: false,
  useDrawer: false,
  themeOfNewBadge: 'flat',
  withAddToCart: true,
  withGoToProduct: false,
  withRating: true
}
