/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface Przelewy24TransactionOutput {
  token: string
  transaction: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum CheckoutAgreement {
  EmailPromotion = 'email-promotion',
  SmsPromotion = 'sms-promotion'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_VIEW_KEY = 'CheckoutView'

export enum CheckoutComponent {
  Checkout = 'Checkout',
  CheckoutTwo = 'CheckoutTwo'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CheckoutViewConfig {
  componentName: string
  displayFreeDeliveryValue: boolean
  displayProducts: boolean
  hasRegionField: boolean
  requireRegionFieldInPayload: boolean
  shouldHaveBrandsWrapper: boolean
  summaryHeading: string | null
  truckIcon: string | null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_VIEW_CONFIG_MAP: CheckoutViewConfig = {
  componentName: CheckoutComponent.CheckoutTwo,
  displayFreeDeliveryValue: false,
  displayProducts: true,
  hasRegionField: false,
  requireRegionFieldInPayload: false,
  shouldHaveBrandsWrapper: true,
  summaryHeading: null,
  truckIcon: null
}
