// Copyright © 2021 Move Closer

import { AppConfig } from '@movecloser/front-core'

import { COMPONENTS_STRUCTURE_CONFIG, MagentoVersion, PRIVACY_CONFIG } from '@core'

import { agreements, AGREEMENTS_CONFIG } from './agreements'
import { AppModules as modules } from './modules'
import { appQueries as queries } from './queries'
import { http } from './http'
import { configuration } from './components'
import { extensions } from './extensions'
import { middlewares } from './middlewares'
import { modalsRegistry as modals } from './modal'
import { patchesRegistry as patches } from './patches'
import { privacy } from './privacy'
import { resources } from './resources'
import { ROUTER_DRIVER as router } from './router'
import { services } from './services'
import { analytics } from './analytics'
import { STORE_DRIVER as store } from './store'
import { SEARCH_CONFIG, searchConfig } from './search'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const config: AppConfig = {
  extensions,
  http,
  [AGREEMENTS_CONFIG]: agreements,
  [COMPONENTS_STRUCTURE_CONFIG]: configuration,
  magentoVersion: MagentoVersion.Enterprise,
  middlewares,
  modals,
  modules,
  nonProductionEnvironments: ['local'],
  queries,
  patches,
  [PRIVACY_CONFIG]: privacy,
  resources,
  router,
  [SEARCH_CONFIG]: searchConfig,
  services,
  store,
  analytics,
  // Has to be defined, even if it's empty, or an error will be thrown.
  modalConfig: {}
}
