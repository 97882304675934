
import { ElementIdentifier } from '@movecloser/page-builder'

import { AnyModule, IRelatedService } from '../../contexts'

import { logger } from '../logger'
import { snippetResolversMap } from './config'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const snippetModulesResolver = async (
  modules: Array<AnyModule>,
  relatedService: IRelatedService
): Promise<Record<ElementIdentifier, AnyModule>> => {
  let resolvedModules: Record<ElementIdentifier, AnyModule> = {}

  try {
    await Promise.all(modules.map(async (module) => {
      const { content, driver } = module
      if (typeof snippetResolversMap[driver] === 'function' && typeof content !== 'undefined') {
        try {
          resolvedModules = {
            ...resolvedModules,
            [module.id]: {
              ...module,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              content: await snippetResolversMap[driver](content, relatedService)
            }
          }
        } catch (e) {
          logger(e, 'warn')
          return module
        }
      }
    }))
  } catch (e) {
    logger(e, 'warn')
  }

  return resolvedModules
}
