// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const selenaFormsResources: ResourcesRegistry = {
  certificates: {
    connection: 'certificates',
    prefix: 'api',
    methods: {
      list: {
        url: 'certifications',
        method: Methods.Get,
        auth: true
      },
      optionsList: {
        url: 'dictionary/{name}',
        params: ['name'],
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'certifications',
        method: Methods.Post,
        auth: true
      },
      upload: {
        url: 'certifications/{id}/files',
        method: Methods.Post,
        params: ['id'],
        auth: true
      }
    }
  },
  // certificatesContent: {
  //   connection: 'wp',
  //   prefix: '',
  //   methods: {
  //     content: {
  //       url: 'pl/wp-json/wp/v2/pages?slug=moje-konto/certyfikaty-izolacji&_fields[]=acf&acf_format=standard',
  //       method: Methods.Get,
  //       auth: true
  //     }
  //   }
  // },
  forms: {
    connection: 'certificates',
    prefix: 'api',
    methods: {
      postTo: {
        url: 'form/{name}/register',
        method: Methods.Post,
        params: ['name'],
        auth: true
      },
      webinars: {
        url: 'webinars',
        method: Methods.Get,
        auth: true
      }
    }
  },
  trainings: {
    connection: 'certificates',
    prefix: 'api',
    methods: {
      list: {
        url: 'trainings',
        method: Methods.Get,
        auth: true
      },
      register: {
        url: 'training/{id}/register',
        params: ['id'],
        method: Methods.Post,
        auth: true
      }
    }
  },
  // materials: {
  //   prefix: '',
  //   connection: 'wp',
  //   methods: {
  //     downloadMaterials: {
  //       url: 'pl/wp-json/acf/v3/pages/97',
  //       method: Methods.Get,
  //       auth: false
  //     },
  //     trainingMaterials: {
  //       url: 'pl/wp-json/acf/v3/pages/264',
  //       method: Methods.Get,
  //       auth: false
  //     }
  //   }
  // },
  // myAccount: {
  //   prefix: '',
  //   connection: 'wp',
  //   methods: {
  //     trainingContent: {
  //       url: 'pl/wp-json/wp/v2/pages?slug={slug}&_fields[]={field}',
  //       method: Methods.Get,
  //       params: ['slug', 'field'],
  //       auth: false
  //     }
  //   }
  // }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resources: ResourcesRegistry = {
  ...selenaFormsResources,
  content: {
    connection: 'api',
    prefix: 'registry',
    methods: {
      get: {
        url: '{slug}',
        method: Methods.Get,
        params: ['slug']
      }
    }
  },
  jobOffers: {
    connection: 'api',
    prefix: '',
    methods: {
      search: {
        url: 'search/offers',
        method: Methods.Get
      }
    }
  },
  newsletter: {
    connection: 'api',
    prefix: 'newsletter',
    methods: {
      subscribe: {
        url: 'subscribe',
        method: Methods.Post
      }
    }
  },
  root: {
    connection: 'api',
    prefix: '',
    methods: {
      search: {
        url: 'search',
        method: Methods.Get
      }
    }
  },
  setup: {
    connection: 'api',
    prefix: '',
    methods: {
      init: {
        url: 'init',
        method: Methods.Get,
        meta: { omitSite: true }
      }
    }
  },
  usageCalculator: {
    connection: 'api',
    prefix: '',
    methods: {
      getPdf: {
        url: 'calculator',
        method: Methods.Post,
        // meta: { omitSite: true }
      }
    }
  }
}
