// Copyright © 2021 Move Closer

import { MetaPropertyName, MetaPropertyProperty } from 'vue-meta'

import { ContentMeta, IRelatedService } from '@core'

type MetaKey = 'name' | 'property'

type MetaType = {
  name: string
  key?: MetaKey
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
function getKey (name: string, metaTag: MetaType): MetaKey {
  if (metaTag && metaTag.key) {
    return metaTag.key ?? 'property'
  }

  return name.indexOf(':') >= 0 ? 'property' : 'name'
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export async function buildMetaTags (
  contentMeta: ContentMeta,
  relatedService: IRelatedService
): Promise<(MetaPropertyName | MetaPropertyProperty)[]> {
  const meta: (MetaPropertyName | MetaPropertyProperty)[] = []
  const metaTags: { [key: string]: MetaType } = {
    ogDescription: {
      name: 'og:description',
      key: 'property'
    },
    ogImage: {
      name: 'og:image',
      key: 'property'
    },
    ogTitle: {
      name: 'og:title',
      key: 'property'
    },
    twitterDescription: { name: 'twitter:description', key: 'name' },
    twitterImage: { name: 'twitter:image', key: 'name' },
    twitterTitle: { name: 'twitter:title', key: 'name' },
    robots: { name: 'robots', key: 'name' }
  }

  if (typeof contentMeta.robots !== 'string') {
    contentMeta.robots = 'index, follow'
  }

  /**
   * If there is og:description, then set description to og:description.
   * If there is no og:description, but there is description, then set og:description to description.
   * If there is no og:title, but there is title, then set og:title to title.
   */
  if (contentMeta.ogDescription) {
    contentMeta.description = contentMeta.ogDescription
  } else if (contentMeta.description && !contentMeta.ogDescription) {
    contentMeta.ogDescription = contentMeta.description
  }
  if (contentMeta.title && !contentMeta.ogTitle) {
    contentMeta.ogTitle = contentMeta.title
  }

  for (let [p, value] of Object.entries(contentMeta)) {
    const metaTag = metaTags[p as keyof typeof metaTags]
    const name = metaTag ? metaTag.name ?? p : p
    const key: MetaKey = getKey(name, metaTag)

    try {
      if (value && typeof value !== 'number' && typeof value !== 'string' && 'type' in value) {
        const selectedRatios = []
        const resolved = await relatedService.resolve(value)

        for (let i = 0; i < resolved.srcset.length; i++) {
          if (resolved.srcset[i].ratio === 'original') {
            selectedRatios.push(resolved.srcset[i])
          }
        }

        selectedRatios.sort((a, b) => {
          return b.width - a.width
        })

        value = selectedRatios[0].url
      }

      if (value && typeof value !== 'number' && typeof value !== 'string' && 'url' in value) {
        value = value.url as string
      }

      const metaItem = {
        [key]: name,
        content: value
      }

      if (key === 'name') {
        meta.push(metaItem as MetaPropertyName)
      } else {
        meta.push(metaItem as MetaPropertyProperty)
      }
    } catch (e) {
      console.info(e, typeof value)
    }
  }

  return meta
}
