
import { AnyObject } from '@movecloser/front-core'

import { ShipmentRegion } from '../../shared/services/dictionary'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const mapRegion = (region: AnyObject): { region: ShipmentRegion } => {
  return {
    region: {
      name: region.label,
      code: region.code,
      id: region.region_id
    }
  }
}
