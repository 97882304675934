import {
  CompanyCreateInput,
  ShortCreateCompanyInput, UpdateCompanyInput
} from '../../../../contexts/profile/contracts/company'

import { TokenModel } from '../../../auth/contracts/models'

import { CompanyModel } from '../../contracts/models'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ICompanyRepository {
  /**
   * Returns details about the user's company.
   */
  getCompanyData (): Promise<CompanyModel>

  /**
   * Creates a company.
   *
   * The email address assigned to the account cannot already exist in the system.
   * Therefore, a customer attempting to create a company cannot use the same email address they used to log in to the storefront
   * See more: https://developer.adobe.com/commerce/webapi/graphql/schema/b2b/company/mutations/create/
   */
  createCompany (companyInput: CompanyCreateInput): Promise<CompanyModel>

  /**
   * Checks whether the specified email can be used to create a company account.
   */
  isCompanyEmailAvailable (email: string | null): Promise<boolean>

  /**
   * Custom mutation that creates company with minimal required input data as well as creates user
   */
  shortCreateCompany (companyInput: ShortCreateCompanyInput): Promise<TokenModel>

  /**
   * Updates company
   */
  updateCompany (companyInput: UpdateCompanyInput): Promise<CompanyModel>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CompanyRepositoryType = Symbol.for('ICompanyRepository')
