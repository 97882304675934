
import { IConnector, Injectable, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors, resolveFromStatus } from '../../../../support'

import { CompanyValidationOutput, INipValidationService } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class NipValidationService implements INipValidationService {
  private connector: IConnector

  constructor (connector: IConnector) {
    this.connector = connector
  }

  /**
   * @inheritDoc
   */
  public async validateCompanyVatId (vatId: string): Promise<CompanyValidationOutput> {
    const response: IResponse = await this.connector.call(
      'nip',
      'companyInformation',
      {},
      { nip: vatId },
      { 'Access-Control-Allow-Origin': '*' }
    )

    if (response && response.data.message) {
      throw new ResourceActionFailed(
        response.data.message,
        ConnectorErrors.Validation,
        response.data
      )
    }

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.data.message,
        resolveFromStatus(response.status),
        {}
      )
    }

    return response.data[0] as CompanyValidationOutput
  }
}
